<template>
    <div class="brand">
        <div class="search">
            <el-form ref="pageInfo" :model="pageInfo" label-width="100px">

                        <el-form-item label="品牌名称：">
                            <el-input v-model="pageInfo.brandName"></el-input>
                        </el-form-item>

                    <!-- <el-col :span="4">
                        <el-form-item label="审核状态：">
                            <el-input></el-input>
                        </el-form-item>
                    </el-col> -->
                    <div style="margin-bottom: 22px; display: inline-block; vertical-align: middle; padding-left: 30px">
                        <el-button type="primary" @click="searchHandle" icon="el-icon-search">查询</el-button>
                        <a class="clear cp" @click="clearSearch">清空</a>
                    </div>


            </el-form>
        </div>

        <div style="height: 20px; background: #F6F7F9;"></div>
        <div class="content">
            <h4 class="flex f_jc_b">
               <span>品牌列表</span>  
               <el-button type="primary" @click="addRole" icon="el-icon-search">新增品牌</el-button>
            </h4>
            <div style="padding: 0 24px">
                <el-table border :data="tableData">
                <!-- <el-table-column
                            type="selection"
                            width="55">
                    </el-table-column> -->
                    <el-table-column prop="brandName" label="品牌名称"></el-table-column>
                    <el-table-column prop="logoUrl"  label="品牌logo">
                        <template slot-scope="scope">
                            <img class="logo" :src="scope.row.logoUrl" alt="">
                        </template>
                    </el-table-column>
                    <el-table-column prop="supplyCode" label="排序"></el-table-column>
                    <el-table-column prop="createTime" label="添加时间"></el-table-column>
                    <el-table-column prop="name" label="操作">
                        <template slot-scope="scope">
                            <el-button  @click="editRole(scope.row)" type="text" size="small">编辑</el-button>
                            <el-popconfirm
                                    icon="el-icon-info"
                                    icon-color="red"
                                    @confirm="delRole(scope.row.id)"
                                    title="是否确认删除品牌?"
                                    >
                                    <el-button slot="reference" style="margin-left: 12px"  type="text" size="small">删除</el-button>
                                </el-popconfirm>
                            
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div style="text-align: right">
                <div style="margin-top: 12px; display: inline-block">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="pageInfo.pageNo"
                        :page-sizes="[10, 20, 50, 100]"
                        :page-size="pageInfo.pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="pageInfo.total">
                    </el-pagination>
                </div>
            </div>
        </div>
        <el-dialog :title="dialogTitle" width="40%" :visible.sync="roleDialog">
            <el-form :model="form" ref="cdForm" style="width: 80%; margin: 20px auto 0;" :rules="rules" label-width="80px">
                <el-form-item label="品牌名称" label-width="100px" prop="brandName">
                    <el-input v-model="form.brandName"  autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="品牌logo"  label-width="100px" prop="logoUrl">
                    <el-upload
                        class="avatar-uploader"
                        action="auth-web/uploadFileOSS"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess"
                        :before-upload="beforeAvatarUpload">
                        <img v-if="form.logoUrl" :src="form.logoUrl" class="avatar">
                        <div v-else class="ta_c">
                            <i  style="color: #FCAC19" class="el-icon-circle-plus fs24 mt30 avatar-uploader-icon"></i>
                            <div class="ta_c hint mt10 mb5">支持jpg/png格式 </div>
                            <div class="ta_c hint"> RGB模式，不超过10M</div>
                           
                        </div>
                    </el-upload>
                </el-form-item>

                
            </el-form>
            <div slot="footer" class="dialog-footer" style="text-align: right">
                <el-button @click="roleDialog = false">取 消</el-button>
                <el-button type="primary" @click="add">确 定</el-button>
            </div>
      </el-dialog>
        <div class="zw"></div>
        <div class="footer"></div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            
            tableData: [],
            dialogFormVisible: false,
            form: {},
            roleName: '',
            dialogTitle: '新增品牌',
            roleDialog: false,
            checkedList: [],
            pageInfo: {
                pageNo: 1,
                pageSize: 10,
                total: 0,
                brandName: ''
            },
            rules: {
                brandName: {required: true, message: '请输入品牌名称', trigger: 'blur'},
                logoUrl: {required: true, message: '请选择logo图片', trigger: 'change'}
            },
            selectedList: [],
            data: [],
            defaultProps: {
                children: 'children',
                label: 'moduleName'
            }

        }
    },
     mounted(){
         this.search()
    },
    methods:{
        handleAvatarSuccess(res, file) {
            console.log(res)
        this.form.logoUrl = res.data.url
      },
      beforeAvatarUpload(file) {
        const isJPG = file.type === 'image/jpeg';
        const isLt2M = file.size / 1024 / 1024 < 10;

        // if (!isJPG) {
        //   this.$message.error('上传头像图片只能是 JPG 格式!');
        // }
        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 10MB!');
        }
        return  isLt2M;
      },
        search(){
            let params = {
                pageNo: this.pageInfo.pageNo,
                pageSize: this.pageInfo.pageSize,
                brandName: this.pageInfo.brandName
            };
            this.qa.queryForPageBrandList(params).then(res => {
                if(res.respCode == "0000") {
                    this.tableData = res.data;
                    this.pageInfo.total = res.total;
                } else {
                    this.$message.error(res.msg);
                }
            })
        },
        searchHandle() {
            this.search()
        },
      addRole(){
          this.dialogTitle = "新增品牌";
          this.form={
            brandName: '',
            logoUrl: ''
          };
          this.roleDialog = true;
      },
      editRole(row){
          this.dialogTitle = "编辑品牌";
          this.form={
              id: row.id,
              brandName: row.brandName,
              logoUrl: row.logoUrl
          };
          this.roleDialog = true;
      },
        clearSearch() {
            this.pageInfo.brandName = ''
            this.search()
        },
      delRole(id){
                this.qa.doLogicDeleteByIdBrand({id: id}).then(res => {
                    this.$message({
                    message: '删除成功',
                    type: 'success'
                    });
                    this.search();
                })

        },
        add(){
            this.$refs.cdForm.validate((valid) => {
                if (valid) {    
                    if(this.dialogTitle == '新增品牌') {
                        this.qa.doInsertBrand(this.form).then(res => {
                            if(res.respCode == "0000") {
                                this.$message({
                                        message: '新增品牌成功！',
                                        type: 'success'
                                    });
                                    this.roleDialog = false;
                                    this.search();
                            } else {
                                this.$message.error(res.msg);
                            }
                        })
                    } else {
                        this.qa.doUpdateBrand(this.form).then(res => {
                            if(res.respCode == "0000") {
                                this.$message({
                                        message: '编辑品牌成功！',
                                        type: 'success'
                                    });
                                    this.roleDialog = false;
                                    this.search();
                            } else {
                                this.$message.error(res.msg);
                            }
                        })
                    }
                } else {
                    return false;
                }
            })
            
        },




        handleSizeChange(page){
            this.pageInfo.pageSize = page;
            this.search();

        },
        handleCurrentChange(page) {
            this.pageInfo.pageNo = page;
            this.search();
        },
        
    }
}
</script>

<style scoped>
  .search {
      background: #fff;
      padding-top: 24px;
  }
  .search >>> .el-form .el-form-item {
      display: inline-block;
      vertical-align: middle;
  }
  .search >>> .el-form .el-input__inner {
      height: 34px;
  }
  .search >>> .el-button {
      padding: 9px 20px;
  }
  .search >>> .el-col {
      height: 34px;
  }
  .search >>> .el-form-item__label {
      line-height: 34px;
  }
  .search >>> .el-form-item__content {
      line-height: 34px;
  }
  .content >>> .el-tabs__nav-wrap {
      padding: 0 28px;
  }
  .content >>> .el-tabs .is-active {
      background: rgba(25, 145, 252, 0.1);
  }
  .content >>> .el-tabs .el-tabs__item {
      /* padding: 0; */
      

  }
  .content >>> .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
      padding-left: 20px;
  }
  .content >>> .el-tabs--top .el-tabs__item.is-top:last-child {
      padding-right: 20px;
  }
  .clear {
      font-size: 14px;
      color: #8C9099;
      margin-left: 24px;
  }
  .content {
      /* padding: 24px; */
      padding: 24px 0;
      background: #fff;
  }
  .content >h4 {
      padding: 0 24px;
      margin-bottom: 20px;
  }

  .wRound {
    width: 13px;
    height: 13px;
    border-radius: 50%;
    border: 1px solid #F53333;
  }
  .nRound {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #F53333;
  }
  .logo {
        width: 100px;        
    }
    .brand >>> .el-upload  {

        width: 240px;
        height: 180px;
        background: #F2F2F2;
    }
    .brand >>> .avatar{
        width: 240px;
        height: 180px;
    }
    .hint{
        line-height: 20px !important;
    }
</style>